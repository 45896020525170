export const misc = {
  notifications: {
    name: 'Notifications',
    path: '/notifications',
    title: 'menu.notifications',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Notifications`,
    },
  },
  notFound: {
    name: 'Not Found Page',
    path: '/:pathMatch(.*)*',
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Not Found`,
    },
  },
  noLectureLinks: {
    name: 'No Lecture Links',
    path: '/no-lecture-links',
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | No Lecture Links`,
    },
  },
  error: 'Error Page',
};
