import type {
  CourseWithModifiedInstructors,
  DiscussionBoardComment,
  IGetStudentSections,
} from '@/helpers/interfaces/course.interface';
import type { CourseCompletion } from '@/helpers/interfaces/lms.interface';
import type { RubricAssessment } from '@/helpers/interfaces/rubric.interface';
import type { SingleSpecialization } from '@/helpers/interfaces/specialization.interface';
import type { Survey } from '@/helpers/interfaces/survey.interface';
import { axiosI } from '@/plugins';
import type { AssignmentSubmission } from '@/submodules/generated_types/types/assignment_submission';
import type { CourseAnnouncement } from '@/submodules/generated_types/types/course_announcement';
import type { CourseAssignment } from '@/submodules/generated_types/types/course_assignment';
import type { CourseDiscussionBoardItem } from '@/submodules/generated_types/types/course_discussion_board_item';
import type { CourseGrade } from '@/submodules/generated_types/types/course_grade';
import type { CourseOnCampusLecture } from '@/submodules/generated_types/types/course_on_campus_lecture';
import type { CourseOnlineLecture } from '@/submodules/generated_types/types/course_online_lecture';
import type { CourseSubsection } from '@/submodules/generated_types/types/course_subsection';
import type { ExamAttempt } from '@/submodules/generated_types/types/exam_attempt';
import type { StudentCourseGrade } from '@/submodules/generated_types/types/student_course_grade';
import type { SubsectionAttachments } from '@/submodules/generated_types/types/subsection_attachments';
import type { SubsectionNotes } from '@/submodules/generated_types/types/subsection_notes';
import type { User } from '@/submodules/generated_types/types/user';
import { examSystemApiHandler } from '@/postman-to-ts/apiHandler/examSystem';
import { lMSApiHandler } from '@/postman-to-ts/apiHandler/lMS';
import { ApiHandler as generatedApiHandler } from '@/submodules/postman-to-ts/apiHandler';
import { CourseNotes } from '@/helpers/interfaces/course_notes';
import {
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';
import type { Ref } from 'vue';
import { useCoursesStore } from '@/stores/course.store';
import type { CourseReviewResponse } from '@/helpers/interfaces/course-review.interface';

const course = createQueryKeys('course', {
  getCourse: (courseId: Ref<string | number>) => ({
    queryKey: ['course', courseId],
    queryFn: async () => {
      const { path, method } = lMSApiHandler.courses.getCourse;
      return (
        await axiosI.get<CourseWithModifiedInstructors>(
          path({ courseId: courseId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
  getMyGroup: (courseId: Ref<string | number>) => ({
    queryKey: ['myGroup', courseId],
    queryFn: async () => {
      const { path, method } = lMSApiHandler.courses.groups.getCurrentGroup;
      return (
        await axiosI.get<{
          id: number | null;
          name: string;
          students: { student: User }[];
        }>(path({ courseId: courseId.value }), {
          method,
        })
      ).data;
    },
  }),
});

const courseSections = createQueryKeys('courseSections', {
  getAll: (courseId: string | number) => ({
    queryKey: ['courseSections', courseId],
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courses.sections.getStudentSections;
      return (
        await axiosI.get<IGetStudentSections>(path({ courseId }), {
          method,
        })
      ).data;
    },
  }),
  getProgress: (courseId: string | number) => ({
    queryKey: ['courseSectionsProgress', courseId],
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courses.sections.getStudentProgress;
      return (
        await axiosI.get<{ [key: string]: number[] }>(path({ courseId }), {
          method,
        })
      ).data;
    },
  }),
  //________________ get subsections ________________
  getSubsection: ({
    subsectionId,
  }: {
    subsectionId: Ref<string | number>;
  }) => ({
    queryKey: ['subsection', subsectionId],
    queryFn: async () => {
      if (!subsectionId.value) return;
      const { path, method } =
        lMSApiHandler.courseResources.courseSubsections.getSubsection;
      return (
        await axiosI<
          CourseSubsection & {
            isCompleted: boolean;
            isLocked: boolean;
            unlockStartDate: string;
          }
        >(
          path({
            subsectionId: +subsectionId.value,
          }),
          {
            method,
          }
        )
      ).data;
    },
    keepPreviousData: true,
  }),

  getSubsectionIds(courseId: Ref<string | number>) {
    return {
      queryKey: ['subsectionIds', courseId],
      queryFn: async () => {
        const { path, method } =
          lMSApiHandler.courses.subsections.getCourseSubsectionIds;
        return (
          await axiosI<{ id: number }[]>(path({ courseId: courseId.value }), {
            method,
          })
        ).data;
      },
    };
  },

  getLastVisitedSubsection(courseId: Ref<string | number>) {
    return {
      queryKey: ['lastVisitedSubsection', courseId],
      queryFn: async () => {
        const { path, method } =
          lMSApiHandler.courses.sections.getLastVisitedSubsection;
        return (
          await axiosI<{ id: number }>(path({ courseId: courseId.value }), {
            method,
          })
        ).data;
      },
    };
  },

  getCompletionStatus(courseId: Ref<string | number>) {
    return {
      queryKey: ['completionStatus', courseId],
      queryFn: async () => {
        if (!useCoursesStore().getCourse(courseId.value.toString())?.isEnrolled)
          return {} as CourseCompletion;
        const { path, method } =
          lMSApiHandler.courses.completion.getCourseIsCompletedFlag;
        return (
          await axiosI<CourseCompletion>(path({ courseId: courseId.value }), {
            method,
          })
        ).data;
      },
    };
  },
});

const courseExams = createQueryKeys('exams', {
  getPreviousExamAttempts: (subsectionExamId: Ref<string | number>) => ({
    queryKey: ['examAttempts', subsectionExamId],
    queryFn: async () => {
      const { path, method } =
        examSystemApiHandler.student.getPreviousExamAttempts;
      return (
        await axiosI<{
          count: number;
          data: ExamAttempt[];
        }>(path({ subsectionExamId: subsectionExamId.value }), {
          method,
        })
      ).data;
    },
  }),
});

const courseNotes = createQueryKeys('courseNotes', {
  getAll: (courseId: Ref<string | number>) => ({
    queryKey: ['courseNotes', courseId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } = lMSApiHandler.courses.notes.getCourseNotes;
      return (
        await axiosI.get<CourseNotes[]>(path({ courseId: courseId.value }), {
          method,
        })
      ).data;
    },
  }),
});

const courseSubsectionNotes = createQueryKeys('courseSubsectionNotes', {
  getAll: (subsectionId: Ref<string | number>) => ({
    queryKey: [subsectionId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseSubsections.notes
          .getSubsectionNotes;
      return (
        await axiosI.get<SubsectionNotes[]>(
          path({ subsectionId: subsectionId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
});

const courseAnnouncements = createQueryKeys('courseAnnouncements', {
  getAnnouncement: (announcementId: Ref<string | number>) => ({
    queryKey: [announcementId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseAnnouncements.getSingleAnnouncement;
      return (
        await axiosI.get<CourseAnnouncement>(
          path({ announcementId: announcementId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
});

const courseSurveys = createQueryKeys('courseSurveys', {
  getSurvey: (surveyId: Ref<number>) => ({
    queryKey: [surveyId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseSurveys.getSurveyById_S;
      return (
        await axiosI<Survey>(path({ surveyId: surveyId.value }), {
          method,
        })
      ).data;
    },
  }),
});

const courseAssignments = createQueryKeys('courseAssignments', {
  getAssignment: (assignmentId: Ref<string | number>) => ({
    queryKey: [assignmentId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseAssignments
          .getCourseAssignmentStudent;
      return (
        await axiosI.get<
          CourseAssignment & {
            submission: AssignmentSubmission;
            studentGrade: StudentCourseGrade;
          }
        >(path({ assignmentId: assignmentId.value }), {
          method,
        })
      ).data;
    },
  }),
});

const courseOnlineLectures = createQueryKeys('courseOnlineLectures', {
  getOnlineLecture: (onlineLectureId: Ref<string | number>) => ({
    queryKey: [onlineLectureId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseOnlineLectures
          .getSingleOnlineLecture;
      return (
        await axiosI.get<CourseOnlineLecture>(
          path({ onlineLectureId: onlineLectureId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
});

const courseOnCampusLectures = createQueryKeys('courseOnCampusLectures', {
  getOnCampusLecture: (onCampusLectureId: Ref<string | number>) => ({
    queryKey: [onCampusLectureId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseOnCampusLectures.getOnCampusLecture;
      return (
        await axiosI.get<CourseOnCampusLecture>(
          path({ onCampusLectureId: onCampusLectureId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
});

const courseDiscussionBoard = createQueryKeys('courseDiscussionBoard', {
  getDiscussionBoard: (discussionBoardId: Ref<string | number>) => ({
    queryKey: [discussionBoardId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseDiscussionBoards
          .getDiscussionBoardById;
      return (
        await axiosI.get<CourseDiscussionBoardItem>(
          path({ discussionBoardId: discussionBoardId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),

  getDiscussionBoardComment: (commentId: number) => ({
    queryKey: ['comment', commentId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseDiscussionBoards
          .discussionBoardComments.getDiscussionBoardCommentById;

      return (
        await axiosI.get<DiscussionBoardComment>(
          path({ commentId: commentId }),
          {
            method,
          }
        )
      ).data;
    },
  }),
});

const courseSubsectionResources = createQueryKeys('courseSubsectionResources', {
  getCourseResources: (subsectionId: Ref<string | number>) => ({
    queryKey: [subsectionId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseSubsections.attachments
          .getSubsectionAttachments;
      return (
        await axiosI.get<SubsectionAttachments[]>(
          path({ subsectionId: subsectionId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
});

const courseGradeItemResources = createQueryKeys('courseGradeItemResources', {
  getCourseGradeItem: (gradeItemId: Ref<string | number>) => ({
    queryKey: [gradeItemId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseGradeItems.getCourseGradeItem;

      return (
        await axiosI.get<CourseGrade>(
          path({ gradeItemId: gradeItemId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
  getStudentGradeItem: ({
    gradeItemId,
    studentId,
  }: {
    gradeItemId: Ref<string | number>;
    studentId: string | number;
  }) => ({
    queryKey: ['student', gradeItemId.value, studentId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.courseResources.courseGradeItems.grades.createItemGrade
          .getStudentItemGrade;

      return (
        await axiosI.get<
          StudentCourseGrade & {
            rubricAssessment: RubricAssessment;
            assignmentSubmission: AssignmentSubmission;
          }
        >(path({ gradeItemId: gradeItemId.value, studentId: studentId }), {
          method,
        })
      ).data;
    },
  }),
});

const specialization = createQueryKeys('specializations', {
  getSpecialization: (specializationId: Ref<number>) => ({
    queryKey: [specializationId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.specializations.getSingleSpecialization;
      return (
        await axiosI<SingleSpecialization>(
          path({ specializationId: specializationId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),
});

const courseReview = createQueryKeys('courseReview', {
  getUserCourseReview: (
    courseId: string | number,
    userId: string | number
  ) => ({
    queryKey: [courseId],
    keepPreviousData: true,
    queryFn: async (): Promise<CourseReviewResponse[]> => {
      const { path, method } =
        generatedApiHandler.lMS.courses.courseReview.getMyReview;
      return (
        await axiosI<{ data: CourseReviewResponse[]; count: number }>(
          path({ courseId }),
          {
            method,
            params: {
              userId,
            },
          }
        )
      ).data.data;
    },
  }),
});

export const lmsQueryKeys = mergeQueryKeys(
  course,
  courseSections,
  courseExams,
  courseSubsectionNotes,
  courseAnnouncements,
  courseAssignments,
  courseSurveys,
  courseOnlineLectures,
  courseOnCampusLectures,
  courseSubsectionResources,
  courseDiscussionBoard,
  courseGradeItemResources,
  specialization,
  courseNotes,
  courseReview
);
