import { EnumRoutes } from '@/helpers/enums/routes.enum';
import { RouterView, type RouteRecordRaw } from 'vue-router';

export const lmsRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.lms.root.path,
    children: [
      {
        path: '',
        name: EnumRoutes.lms.root.name,
        component: () => import('@/views/lms/LmsLayout.vue'),
        meta: EnumRoutes.lms.root.meta,
        children: [
          // hide for now
          // {
          //   name: EnumRoutes.lms.specialization.name,
          //   path: EnumRoutes.lms.specialization.path,
          //   component: () => import('@/views/lms/courses/SpecializationView.vue'),
          //   meta: EnumRoutes.lms.specialization.meta,
          // },
          {
            name: EnumRoutes.lms.allCourses.name,
            path: EnumRoutes.lms.allCourses.path,
            component: () => import('@/views/lms/courses/AllCourses.vue'),
            meta: EnumRoutes.lms.allCourses.meta,
          },
          {
            name: EnumRoutes.lms.myCourses.name,
            path: EnumRoutes.lms.myCourses.path,
            component: () => import('@/views/lms/courses/MyCourses.vue'),
            meta: EnumRoutes.lms.myCourses.meta,
          },
        ],
      },

      {
        name: EnumRoutes.lms.globalCertificatePage.name,
        path: EnumRoutes.lms.globalCertificatePage.path,
        component: () => import('@/views/lms/GlobalCertificatePage.vue'),
        meta: EnumRoutes.lms.globalCertificatePage.meta,
      },
      // course overview, regardless if enrolled or not.
      {
        name: EnumRoutes.lms.courseOverview.name,
        path: EnumRoutes.lms.courseOverview.path,
        component: () => import('@/views/lms/courses/CourseOverviewPage.vue'),
        meta: EnumRoutes.lms.courseOverview.meta,
      },

      {
        //________________ Courses ________________
        name: EnumRoutes.lms.coursePage.name,
        path: EnumRoutes.lms.coursePage.path,
        component: () => import('@/views/lms/courses/CourseBiforcation.vue'),
        meta: EnumRoutes.lms.coursePage.meta,
        children: [
          {
            //actual course data, when enrolled.
            name: EnumRoutes.lms.courseInformation.name,
            path: EnumRoutes.lms.courseInformation.path,
            component: () =>
              import('@/views/lms/courses/content/CourseInformation.vue'),
            meta: EnumRoutes.lms.courseInformation.meta,
          },
          {
            name: EnumRoutes.lms.courseSyllabus.name,
            path: EnumRoutes.lms.courseSyllabus.path,
            component: () =>
              import('@/views/lms/courses/content/CourseSyllabus.vue'),
            meta: EnumRoutes.lms.courseSyllabus.meta,
          },
          {
            name: EnumRoutes.lms.courseModules.name,
            path: EnumRoutes.lms.courseModules.path,
            component: () =>
              import('@/views/lms/courses/content/CourseModules.vue'),
            meta: EnumRoutes.lms.courseModules.meta,
          },
          {
            name: EnumRoutes.lms.courseContent.name,
            path: EnumRoutes.lms.courseContent.path,
            component: () =>
              import('@/views/lms/courses/content/CourseModuleItem.vue'),
          },
          // _______________ Certificate ________________
          {
            name: EnumRoutes.lms.courseCertificate.name,
            path: EnumRoutes.lms.courseCertificate.path,
            component: () =>
              import('@/views/lms/courses/content/CourseCertificatePage.vue'),
            meta: EnumRoutes.lms.courseCertificate.meta,
          },

          //________________ Assignments ________________
          {
            name: EnumRoutes.lms.courseAssignments.name,
            path: EnumRoutes.lms.courseAssignments.path,
            component: RouterView,
            children: [
              {
                name: EnumRoutes.lms.courseAssignments.allCourseAssignments
                  .name,
                path: EnumRoutes.lms.courseAssignments.allCourseAssignments
                  .path,
                component: () =>
                  import('@/views/lms/courses/CourseAssignments.vue'),
                meta: EnumRoutes.lms.courseAssignments.allCourseAssignments
                  .meta,
              },
              {
                name: EnumRoutes.lms.courseAssignments.singleCourseAssignment
                  .name,
                path: EnumRoutes.lms.courseAssignments.singleCourseAssignment
                  .path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/assignments/AssignmentPage.vue'
                  ),
                meta: EnumRoutes.lms.courseAssignments.singleCourseAssignment
                  .meta,
              },
            ],
          },

          //________________ Announcments ________________
          {
            name: EnumRoutes.lms.courseAnnouncements.name,
            path: EnumRoutes.lms.courseAnnouncements.path,
            component: RouterView,
            children: [
              {
                name: EnumRoutes.lms.courseAnnouncements.allCourseAnnouncements
                  .name,
                path: EnumRoutes.lms.courseAnnouncements.allCourseAnnouncements
                  .path,
                component: () =>
                  import('@/views/lms/courses/CourseAnnouncements.vue'),
                meta: EnumRoutes.lms.courseAnnouncements.allCourseAnnouncements
                  .meta,
              },
              {
                name: EnumRoutes.lms.courseAnnouncements
                  .singleCourseAnnouncement.name,
                path: EnumRoutes.lms.courseAnnouncements
                  .singleCourseAnnouncement.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/announcements/AnnouncementPage.vue'
                  ),
                meta: EnumRoutes.lms.courseAnnouncements
                  .singleCourseAnnouncement.meta,
              },
            ],
          },

          //________________ Exams ________________
          {
            name: EnumRoutes.lms.courseExams.name,
            path: EnumRoutes.lms.courseExams.path,
            component: RouterView,
            children: [
              {
                name: EnumRoutes.lms.courseExams.allCourseExams.name,
                path: EnumRoutes.lms.courseExams.allCourseExams.path,
                component: () => import('@/views/lms/courses/CourseExams.vue'),
                meta: EnumRoutes.lms.courseExams.allCourseExams.meta,
              },
              {
                name: EnumRoutes.lms.courseExams.examLandingPage.name,
                path: EnumRoutes.lms.courseExams.examLandingPage.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/exams/pages/ExamLandingPage.vue'
                  ),
                meta: EnumRoutes.lms.courseExams.examLandingPage.meta,
              },
              {
                name: EnumRoutes.lms.courseExams.takingExam.name,
                path: EnumRoutes.lms.courseExams.takingExam.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/exams/pages/TakingExamPage.vue'
                  ),
                meta: EnumRoutes.lms.courseExams.takingExam.meta,
              },
              {
                name: EnumRoutes.lms.courseExams.reviewExam.name,
                path: EnumRoutes.lms.courseExams.reviewExam.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/exams/pages/ReviewExamPage.vue'
                  ),
                meta: EnumRoutes.lms.courseExams.reviewExam.meta,
              },
            ],
          },

          //________________ Surveys ________________
          {
            name: EnumRoutes.lms.courseSurveys.surveys.name,
            path: EnumRoutes.lms.courseSurveys.surveys.path,
            component: () =>
              import(
                '@/views/lms/courses/module-items/surveys/CourseSurveys.vue'
              ),
            meta: EnumRoutes.lms.courseSurveys.surveys.meta,
          },
          {
            name: EnumRoutes.lms.courseSurveys.polls.name,
            path: EnumRoutes.lms.courseSurveys.polls.path,
            component: () =>
              import(
                '@/views/lms/courses/module-items/surveys/CoursePolls.vue'
              ),
            meta: EnumRoutes.lms.courseSurveys.polls.meta,
          },
          {
            name: EnumRoutes.lms.courseSurveys.singlePoll.name,
            path: EnumRoutes.lms.courseSurveys.singlePoll.path,
            component: () =>
              import(
                '@/views/lms/courses/module-items/surveys/SingleSurvey.vue'
              ),
            meta: EnumRoutes.lms.courseSurveys.singlePoll.meta,
          },
          {
            name: EnumRoutes.lms.courseSurveys.singleSurvey.name,
            path: EnumRoutes.lms.courseSurveys.singleSurvey.path,
            component: () =>
              import(
                '@/views/lms/courses/module-items/surveys/SingleSurvey.vue'
              ),
            meta: EnumRoutes.lms.courseSurveys.singleSurvey.meta,
          },

          // _________________ Online Lectures ___________
          {
            name: EnumRoutes.lms.courseOnlineLectures.name,
            path: EnumRoutes.lms.courseOnlineLectures.path,
            component: RouterView,
            children: [
              {
                name: EnumRoutes.lms.courseOnlineLectures.allOnlineLectures
                  .name,
                path: EnumRoutes.lms.courseOnlineLectures.allOnlineLectures
                  .path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/online-lectures/CourseOnlineLecturesPage.vue'
                  ),
                meta: EnumRoutes.lms.courseOnlineLectures.allOnlineLectures
                  .meta,
              },
              {
                name: EnumRoutes.lms.courseOnlineLectures.singleOnlineLecture
                  .name,
                path: EnumRoutes.lms.courseOnlineLectures.singleOnlineLecture
                  .path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/online-lectures/OnlineLecturePage.vue'
                  ),
                meta: EnumRoutes.lms.courseOnlineLectures.singleOnlineLecture
                  .meta,
              },
            ],
          },
          // _________________ Notes Lectures ___________
          {
            name: EnumRoutes.lms.courseNotesItems.name,
            path: EnumRoutes.lms.courseNotesItems.path,
            component: () =>
              import('@/views/lms/courses/module-items/notes/CourseNotes.vue'),
          },
          // _________________ On Campus Lectures ___________
          {
            name: EnumRoutes.lms.courseOnCampusLectures.name,
            path: EnumRoutes.lms.courseOnCampusLectures.path,
            component: RouterView,
            children: [
              {
                name: EnumRoutes.lms.courseOnCampusLectures.allOnCampusLectures
                  .name,
                path: EnumRoutes.lms.courseOnCampusLectures.allOnCampusLectures
                  .path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/on-campus-lectures/CourseOnCampusLecturesPage.vue'
                  ),
                meta: EnumRoutes.lms.courseOnCampusLectures.allOnCampusLectures
                  .meta,
              },
              {
                name: EnumRoutes.lms.courseOnCampusLectures
                  .singleOnCampusLecture.name,
                path: EnumRoutes.lms.courseOnCampusLectures
                  .singleOnCampusLecture.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/on-campus-lectures/OnCampusLecturePage.vue'
                  ),
                meta: EnumRoutes.lms.courseOnCampusLectures
                  .singleOnCampusLecture.meta,
              },
            ],
          },
          // _________________ Grade Items ___________
          {
            name: EnumRoutes.lms.courseGradeItems.name,
            path: EnumRoutes.lms.courseGradeItems.path,
            component: RouterView,
            children: [
              {
                name: EnumRoutes.lms.courseGradeItems.allGradeItems.name,
                path: EnumRoutes.lms.courseGradeItems.allGradeItems.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/grade-items/CourseGradeItemsPage.vue'
                  ),
              },
              {
                name: EnumRoutes.lms.courseGradeItems.singleGradeItem.name,
                path: EnumRoutes.lms.courseGradeItems.singleGradeItem.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/grade-items/CourseGradeItemPage.vue'
                  ),
              },
            ],
          },
          // ________________ Discussion boards ___________
          {
            name: EnumRoutes.lms.courseDiscussionBoards.name,
            path: EnumRoutes.lms.courseDiscussionBoards.path,
            component: RouterView,
            children: [
              {
                name: EnumRoutes.lms.courseDiscussionBoards.allDiscussionBoards
                  .name,
                path: EnumRoutes.lms.courseDiscussionBoards.allDiscussionBoards
                  .path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/discussion-boards/CourseDiscussionBoardsPage.vue'
                  ),
                meta: EnumRoutes.lms.courseDiscussionBoards.allDiscussionBoards
                  .meta,
              },
              {
                name: EnumRoutes.lms.courseDiscussionBoards
                  .singleDiscussionBoard.name,
                path: EnumRoutes.lms.courseDiscussionBoards
                  .singleDiscussionBoard.path,
                component: () =>
                  import(
                    '@/views/lms/courses/module-items/discussion-boards/DiscussionBoardPage.vue'
                  ),
                meta: EnumRoutes.lms.courseDiscussionBoards
                  .singleDiscussionBoard.meta,
              },
            ],
          },
          // _________________ Attendance ___________
          {
            name: EnumRoutes.lms.courseAttendance.name,
            path: EnumRoutes.lms.courseAttendance.path,
            component: () =>
              import(
                '@/views/lms/courses/module-items/attendance/StudentAttendance.vue'
              ),
            meta: EnumRoutes.lms.courseAttendance.meta,
          },
          // _________________ Groups ___________
          {
            name: EnumRoutes.lms.courseGroups.name,
            path: EnumRoutes.lms.courseGroups.path,
            component: () => import('@/views/lms/courses/CourseGroups.vue'),
            meta: EnumRoutes.lms.courseGroups.meta,
          },
          // _________________ Review ___________
          {
            name: EnumRoutes.lms.courseReview.name,
            path: EnumRoutes.lms.courseReview.path,
            component: () =>
              import(
                '@/views/lms/courses/module-items/review-course/ReviewCourse.vue'
              ),
            meta: EnumRoutes.lms.courseReview.meta,
          },
        ],
      },
      // hide for now
      // {
      //   name: EnumRoutes.lms.specializationOverview.name,
      //   path: EnumRoutes.lms.specializationOverview.path,
      //   component: () =>
      //     import('@/views/lms/courses/SpecializationOverviewPage.vue'),
      //   meta: EnumRoutes.lms.specializationOverview.meta,
      // },
    ],
  },
];
