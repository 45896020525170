import { Media } from "./media";
import { PermissionSet } from "./permission_set";
import { PaymentGateway } from "./payment_gateway";
import { CertificateTemplate } from "./certificate_template";
import { CoursePassingCriteria } from "./course_passing_criteria";
import { FontFamily } from "./font_family";
import { LearningGroup } from "./learning_group";
import { Checklist } from "./checklist";
import { OrganizationMember } from "./organization_member";
import { OrganizationLink } from "./organization_link";
import { OrganizationColor } from "./organization_color";
import { ProviderTargetOrganization } from "./provider_target_organization";
import { Order } from "./order";
import { OrganizationTaxClass } from "./organization_tax_class";
import { Course } from "./course";
import { Specialization } from "./specialization";
import { SocialGroup } from "./social_group";
import { SocialEvent } from "./social_event";
import { CourseTargetOrganization } from "./course_target_organization";
import { SpecializationTargetOrganization } from "./specialization_target_organization";
import { SocialGroupTargetOrganization } from "./social_group_target_organization";
import { SocialEventTargetOrganization } from "./social_event_target_organization";
import { GradingSchema } from "./grading_schema";
import { CourseDiscussionBoardItemComment } from "./course_discussion_board_item_comment";
import { PurchasedItem } from "./purchased_item";
import { Voucher } from "./voucher";
import { VoucherConsumption } from "./voucher_consumption";
import { QoyodProduct } from "./qoyod_product";
import { OrganizationRefundPolicy } from "./organization_refund_policy";
import { CourseApprovalRequest } from "./course_approval_request";
import { OrderInvoice } from "./order_invoice";
import { OrganizationRegistrationFormField } from "./organization_registration_form_field";
import { User } from "./user";
import { OrganizationConfiguration } from "./organization_configuration";
import { EmailSubscription } from "./email_subscription";
import { OrganizationFontFamily } from "./organization_font_family";
import { OrganizationTerm } from "./organization_term";
import { OrganizationCreditUnitName } from "./organization_credit_unit_name";
import { ExternalCourseTargetOrganization } from "./external_course_target_organization";
import { OrganizationPricingModel } from "./organization_pricing_model";
import { OrganizationFuturePricingModel } from "./organization_future_pricing_model";
import { OrganizationAnnouncement } from "./organization_announcement";
import { ApiKey } from "./api_key";
import { OrganizationFieldGroup } from "./organization_field_group";
import { OrganizationFieldSort } from "./organization_field_sort";
import { Article } from "./article";
import { TopicTag } from "./topic_tag";
import { GalleryMedium } from "./gallery_medium";

export class Organization {
  id: number;

  name: string;

  nameArabic: string;

  domainPrefix: string;

  description?: string;

  copyrightMessage: string = "All rights reserved ®";

  logoId?: number;

  logo?: Media;

  iconId?: number;

  icon?: Media;

  certificateIconId?: number;

  certificateIcon?: Media;

  permissionSetId?: number;

  permissionSet?: PermissionSet;

  defaultMemberPermissionSetId?: number;

  defaultMemberPermissionSet?: PermissionSet;

  currencyCode: string = "SAR";

  paymentGatewayId?: number;

  paymentGateway?: PaymentGateway;

  defaultCertificateTemplateId?: number;

  certificateTemplate?: CertificateTemplate;

  coursePassingCriteriaId?: number;

  coursePassingCriteria?: CoursePassingCriteria;

  fontFamilyId?: number;

  fontFamily?: FontFamily;

  createdAt: Date;

  updatedAt?: Date;

  learningGroups: LearningGroup[];

  checklists: Checklist[];

  members: OrganizationMember[];

  links: OrganizationLink[];

  colors: OrganizationColor[];

  targetProviders: ProviderTargetOrganization[];

  orders: Order[];

  taxClasses: OrganizationTaxClass[];

  courses: Course[];

  specializations: Specialization[];

  socialGroups: SocialGroup[];

  socialEvents: SocialEvent[];

  targetCourses: CourseTargetOrganization[];

  targetSpecializations: SpecializationTargetOrganization[];

  targetSocialGroups: SocialGroupTargetOrganization[];

  targetSocialEvents: SocialEventTargetOrganization[];

  gradingSchemas: GradingSchema[];

  courseDiscussionBoardItemComments: CourseDiscussionBoardItemComment[];

  purchasedItems: PurchasedItem[];

  vouchers: Voucher[];

  voucherConsumption: VoucherConsumption[];

  qoyodProducts: QoyodProduct[];

  refundPolicies: OrganizationRefundPolicy[];

  approvalRequests: CourseApprovalRequest[];

  orderInvoice: OrderInvoice[];

  registrationFormFields: OrganizationRegistrationFormField[];

  users: User[];

  permissionSets: PermissionSet[];

  config: OrganizationConfiguration[];

  newsletterSubscribers: EmailSubscription[];

  uploadedFontFamilies: FontFamily[];

  fontFamilies: OrganizationFontFamily[];

  terminology: OrganizationTerm[];

  creditUnitNames: OrganizationCreditUnitName[];

  externalCourseTargetOrganizations: ExternalCourseTargetOrganization[];

  pricingModel?: OrganizationPricingModel;

  organizationFuturePricingModel?: OrganizationFuturePricingModel;

  announcements: OrganizationAnnouncement[];

  apiKeys: ApiKey[];

  fieldGroups: OrganizationFieldGroup[];

  fieldsSort: OrganizationFieldSort[];

  articles: Article[];

  topicTags: TopicTag[];

  galleryMedia: GalleryMedium[];
  learningCategories: { id: number; name: string }[];
}
